import styled from "styled-components";
import {mediaMax} from "../../../helpers/MediaQueries";

export const ContentWrap = styled.div`
  padding: 0.1% 5% 6%;
  h1 {
    padding-bottom: 48px;
    font-weight: 100;
    font-size: 80px;
    ${mediaMax.phoneXL`
      font-size: 60px;
      padding: 50px 5.2% 20px;
      font-weight: 100;
      text-align: center;
    `}
  }
  ${mediaMax.phoneXL`
    padding: 0 4% 6%;
  `}
`;

export const PressArticles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Article = styled.div`
  max-width: 24%;
  margin: 0 6% 0 10px;
  padding-bottom: 5%;
  ${mediaMax.tabletLandscape`
      max-width: 40%;
      margin: 40px 5%;
  `}
  ${mediaMax.tablet`
      max-width: 100%;
  `}
  h5 {
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
  h4 {
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
  h3 {
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 250;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.8px;
    text-transform: uppercase;


  }
  p {
    padding-bottom: 20px;
  }
  a {
    background: none;
    border: none;
    cursor: pointer;
  }
`;
