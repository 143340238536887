import React, {useContext, useEffect} from "react";
import { seoData } from "../data/seo";
import { navData } from "../data/nav";
import Seo from "../components/seo";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import ContentPress from "../components/press/content-press";
import {CompositionWrapper} from "../components/composition/test/styled.index";
import NewComposition from "../components/composition/test";
import {Context} from "../context";
const PressPage = () => {
  const { setIsMenuLight } = useContext(Context);

  useEffect(()=> {
    setIsMenuLight(false)
  });
  return (
    <main>
      <Seo page={seoData.press} />
      <ContentPress />
      <CompositionWrapper>
        <ContentNextPage
          type={"bg-full"}
          nextTitle={navData.contact.title}
          nextLink={navData.contact.link}
          vertAlign={'center'}
          mobHeight={"40vh"}
        />
        <NewComposition
          shape={"circle"}
          top={"0"}
          right={"93vw"}
          height={"600px"}
          mobBreakpoint={"phoneXL"}
          mobTop={"-10%"}
          mobRight={"90vw"}
          mobHeight={"100%"}
        />
        <NewComposition
          shape={"triangle"}
          top={"0"}
          right={"0"}
          height={"100%"}
          mobBreakpoint={"phoneXL"}
          mobTop={"auto"}
          mobBottom={"0"}
          mobRight={"0"}
          mobHeight={"80%"}
        />
      </CompositionWrapper>
      <Footer />
    </main>
  );
};

export default PressPage;
