import React, { FC, useContext, useEffect, useRef } from "react";
import { ContentWrap, PressArticles, Article } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { graphql, useStaticQuery } from "gatsby";
import { Logo } from "../../contact/contact-body/index.styled";
import IntroLogo from "../../../assets/images/intro_logo_dark.svg";
import { Context } from "../../../context";

const ContentPress: FC = () => {
  const { setIsMenuLight } = useContext(Context)
  const contentWrapRef = useRef(null);
  const articleRef = useRef(null);
  const data = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: {fields: ArticleDate, order: DESC}) {
        edges {
          node {
            id
            ArticleContent
            ArticleDate(formatString: "YYYY.MM.DD")
            ArticleSourceLink
            ArticleTitle
            ArticleName
          }
        }
      }
    }
  `);
  const articles = data.allStrapiArticle.edges
  useEffect(() => {
    ScrollTrigger.create({
      trigger: contentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top bottom",
      end: "bottom bottom",
      animation: gsap
        .timeline()
        .fromTo(
          articleRef?.current || null,
          0.75,
          { opacity: 1, y: 0 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);
  useEffect(() => {
    setIsMenuLight(false);
  }, [])
  return (
    <ContentWrap ref={contentWrapRef}>
      <Logo to={'/'}><img src={IntroLogo} alt="" /></Logo>
      <h1 className={"heading fs-xxl"}>Press</h1>
      <PressArticles ref={articleRef}>
        {articles.map((item: any) => (
          <Article key={item.id} >
            <h5 className={"heading"}>{item.node.ArticleDate}</h5>
            <h4 className={"heading"}>{item.node.ArticleName}</h4>
            <h3 className={"fs-xl heading"}>{item.node.ArticleTitle}</h3>
            <p className={"reg-text"}>{item.node.ArticleContent}</p>
            <a href={item.node.ArticleSourceLink} className={"fs-lbl heading"} target={"_blank"}>VIEW</a>
          </Article>
        ))}
      </PressArticles>
    </ContentWrap>
  );
};
export default ContentPress;

